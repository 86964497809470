import farmstand from '../images/VisitPhoto.jpg'



export default function FarmStand() {
    return (
      <div className="relative  lg:mb-20 ">
        <div className="relative  overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
          <img
            className="h-full w-full object-cover"
            src={farmstand}
            alt=""
          />
          <svg
            viewBox="0 0 926 676"
            aria-hidden="true"
            className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
          >
            <path
              fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
              fillOpacity=".4"
              d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
            />
            <defs>
              <linearGradient
                id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
                x1="926.392"
                x2="-109.635"
                y1=".176"
                y2="321.024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="relative mx-auto max-w-7xl sm:py-16 lg:px-8 py-16">
          <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
            <h2 className="text-base font-medium leading-7 text-pink">Puyallup, WA</h2>
            <p className="mt-2 text-3xl font-medium tracking-tight text-blue italic sm:text-4xl">Visit our <span class="font-bold non-italic" id="friz">FARM STAND!</span></p>
            <p className="mt-6 mb-6 text-pink text-xl  leading-7 text-gray-300">
              Seasonal Hours<br></br> <span class="font-semibold">Closed January - March</span>
              
            </p>
                                           
          
          </div>
        </div>
      </div>
    )
  }
  